<template>
  <header class="align-items-center d-md-flex pb-2">
    <vs-button
      v-if="returnLink.length > 0"
      class="mr-2"
      icon="arrow_back"
      type="flat"
      radius
      @click="goBack"
    />

    <h4
      v-if="filterName"
      class="d-flex align-items-center flex-wrap mb-0 ml-2 text-uppercase"
      :style="{ color: colorSecondary, gap: '0.35rem' }"
    >
      <small :style="{ color: colorPrimary }">
        Selecionado:
      </small>

      <span :style="{ lineHeight: '25px' }">
        {{ filterName }}
      </span>
    </h4>
    <h4
      v-else
      :style="{ color: colorSecondary }"
      class="text-uppercase"
    >
      Selecionando filtros
    </h4>

    <div
      class="d-flex align-items-center ml-auto"
      :style="{ whiteSpace: 'nowrap' }"
    >
      <vs-button
        class="mr-2 mt-4 mt-md-0"
        :color="colorSecondary"
        :disabled="!filterNotEmpty"
        type="filled"
        @click="applyFilter"
      >
        <i class="mdi mdi-filter mr-1" />

        Aplicar Filtro
      </vs-button>

      <vs-button
        class="mr-2 mt-4 mt-md-0"
        color="primary"
        type="filled"
        @click="clearFilter"
      >
        <i class="mdi mdi-minus-circle-outline mr-1" />

        Limpar Filtro
      </vs-button>

      <vs-button
        class="mr-2 mt-4 mt-md-0"
        color="primary"
        type="filled"
        @click="getFilters"
      >
        <i class="mdi mdi-format-list-bulleted mr-1" />

        Meus Filtros
      </vs-button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'FilterHeader',
  props: {
    returnLink: {
      type: String,
      default: '',
    },
    filterName: {
      type: String,
      required: true,
    },
    filterNotEmpty: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
  },
  methods: {
    applyFilter() {
      this.$emit('apply-filter');
    },
    clearFilter() {
      this.$emit('clear-filter');
    },
    getFilters() {
      this.$emit('get-filters');
    },
    /* Router */
    goBack() {
      this.$router.push(this.returnLink);
    },
  },
};
</script>
