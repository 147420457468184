const validateFirstDate = (dateRange) => {
  const validDateLength = 10;

  if (dateRange[0]) {
    if (dateRange[0].length === validDateLength) {
    // First check for the pattern
      if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateRange[0])) {
        return false;
      }

      // Parse the date parts to integers
      let parts = dateRange[0].split('/');

      let day = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10);
      let year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
        monthLength[1] = 29;
      } else {
        monthLength[1] = 28;
      }

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export default validateFirstDate;
