const validateCustomFilter = (
  option,
  searchText,
  dateRange,
  timeRange,
  valueRange,
) => {
  const validDateLength = 10;
  const validTimeLength = 5;

  switch (option) {
  case 'like':
    return searchText.length > 0;
  case 'date':
    if (dateRange.length === 2) {
      return (
        dateRange[0].length === validDateLength
        && dateRange[1].length === validDateLength
      );
    } else {
      return false;
    }
  case 'time':
    if (timeRange.length === 2) {
      return (
        timeRange[0].length === validTimeLength
        && timeRange[1].length === validTimeLength
      );
    } else {
      return false;
    }
  case 'int':
    return valueRange.length === 2;
  default:
    return false;
  }
};

export default validateCustomFilter;
