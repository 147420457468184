import moment from 'moment';

const validateDateRange = (dateRange) => {
  const validDateLength = 10;

  if (dateRange[0] && dateRange[1]) {
    if (
      dateRange[0].length === validDateLength
      && dateRange[1].length === validDateLength
    ) {
      return moment(dateRange[1], 'DD/MM/YYYY')
        .isAfter(moment(dateRange[0], 'DD/MM/YYYY'), 'day');
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export default validateDateRange;
