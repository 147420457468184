<template>
  <div id="filter-container">
    <vs-card>
      <FilterHeader
        :filter-not-empty="filterNotEmpty"
        :filter-name="filterName"
        :return-link="returnLink"
        @apply-filter="applyFilter"
        @clear-filter="clearFilter"
        @get-filters="getFilters"
      />

      <hr class="custom-hr">

      <!-- Main -->
      <vs-row
        vs-justify="start"
        class="mt-2"
      >
        <!-- Categories / Question Groups -->
        <vs-col
          type="flex"
          vs-justify="start"
          vs-align="start"
          vs-lg="4"
          vs-xs="12"
          height="900px"
        >
          <h4 class="align-self-center card-title mb-4 title">
            Categorias
          </h4>

          <div class="centerx">
            <vs-collapse accordion>
              <vs-collapse-item
                v-for="(grp, grpIdx) in questionGroups"
                :key="grpIdx"
                icon-arrow="add"
                :disabled="!creatingFilter"
              >
                <div
                  slot="header"
                  class="font-weight-bold mb-2"
                >
                  <i
                    :class="grp.icon"
                    class="mr-3"
                  />

                  {{ grp.group }}
                </div>

                <vs-card
                  v-for="(box, bIdx) in grp.boxes"
                  :key="bIdx"
                  class="cardx px-2 py-2 shadow"
                  icon-arrow="arrow_drop_up"
                >
                  <div
                    slot="header"
                    class="mb-2 text-uppercase"
                  >
                    {{ box.title }}
                  </div>

                  <!-- Indicator Chips -->
                  <div
                    slot="media"
                    class="pl-1 pr-1"
                  >
                    <div
                      v-for="(indicator, indIdx) in box.indexes"
                      :key="indIdx"
                      class="col-md-12 col-sm-12"
                      @click="showSelectAnswerPopup(indicator)"
                    >
                      <vs-tooltip
                        v-if="!indicator.indId"
                        text="Indicador em manutenção"
                      >
                        <vs-chip
                          class="chips-margin"
                          color="#adaca6"
                        >
                          {{ indicator.idxText }}
                        </vs-chip>
                      </vs-tooltip>

                      <vs-chip
                        v-else
                        :key="answerRenderKey"
                        class="chips-margin"
                        :color="filterAnswers.find((q) => q.id == indicator.indId) ? colorSecondary : 'dark'"
                      >
                        {{ indicator.idxText }}
                      </vs-chip>
                    </div>
                  </div>
                </vs-card>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vs-col>

        <!-- Filter Answers -->
        <vs-col
          v-if="filterNotEmpty"
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-xs="12"
        >
          <div class="align-items-left d-flex justify-content-between">
            <h4 class="align-self-center card-title title">
              Critérios
            </h4>

            <vs-button
              radius
              color="dark"
              type="flat"
              icon="remove_circle_outline"
              data-toggle="tooltip"
              data-placement="top"
              title="Apagar todos"
              @click="clearFilter"
            />
          </div>

          <vs-card
            v-for="(question, questionIdx) in filterAnswers"
            :key="questionIdx"
            class="shadow answer-card"
            icon-arrow="arrow_drop_up"
          >
            <div
              slot="header"
              class="answer-card--header mb-2 text-uppercase cursor-pointer"
              @click="showSelectAnswerPopup(question)"
            >
              <div>
                <i class="mdi mdi-check-all mr-2" />

                {{ question.questionName }}
              </div>

              <vs-chip
                v-if="getOptionsOperator(question.id) && multipleSelected"
                class="small font-medium font-weight-bold"
              >
                Operador:

                {{ getOptionsOperator(question.id) === '&' ? 'E' : 'Ou' }}
              </vs-chip>
            </div>

            <div
              slot="media"
              class="answer-card--content"
            >
              <vs-chip
                v-for="(answer, answerIdx) in question.options"
                :key="answerIdx"
                :closable="creatingFilter"
                class="text-uppercase chips-margin"
                style="color: white"
                color="primary"
                @click="removeAnswer(question.id, answer.option)"
              >
                {{
                  answer.config === '!'
                    || answer.config === '|!'
                    || answer.config === '&!'
                    ? 'NÃO: '
                    : ''
                }}

                {{ answer.option || answer }}
              </vs-chip>
            </div>
          </vs-card>
        </vs-col>

        <ResultsCard
          :csv-url="csvUrl"
          :filter-answers="filterAnswers"
          :filter-id="filterId"
          :filter-name="filterName"
          :filter-saved="filterSaved"
          :filter-uid="filterUid"
          :loading-results="loadingResults"
          :questions="questions"
          :previous-results="previousResults"
          :question-groups="questionGroups"
          :register-count="registerCount"
          @save-filter="saveFilter"
          @set-filter-name="(value) => filterName = value"
        />
      </vs-row>
    </vs-card>

    <!-- Popups -->
    <!-- Select Answer Popup -->
    <vs-popup
      :title="selectedQuestion.idxText || ''"
      :active.sync="selectAnswerPopup"
    >
      <!-- Add Options -->
      <div v-if="showFilterAnswers">
        <h3 class="mt-2">
          Selecionar Opções:
        </h3>

        <div class="create-filter-selected d-flex justify-content-between align-items-start mb-4 mt-3">
          <div
            v-if="multipleSelected && !multipleNegative"
            class="operator-select-container"
          >
            <vs-select
              v-model="selectedOperator"
              label="Operador"
            >
              <vs-select-item
                v-for="(operator, operatorIdx) in operators"
                :key="operatorIdx"
                :value="operator.value"
                :text="operator.text"
              />
            </vs-select>

            <p
              v-if="selectedOperator === '&'"
              class="operator-description mt-2"
            >
              Traz apenas os fãs que satisfazem todas as opções selecionadas.
            </p>

            <p
              v-else
              class="operator-description mt-2"
            >
              Traz todos os fãs que satisfazem uma ou outra das opções selecionadas.
            </p>
          </div>

          <vs-button
            class="ml-auto"
            @click="confirmAnswers"
          >
            <i class="mdi mdi-format-list-bulleted mr-1" />

            Confirmar Seleção
          </vs-button>
        </div>

        <!-- Answers -->
        <vs-table
          v-if="answersNotEmpty"
          search
          striped
          :data="vsAnswers"
        >
          <template slot="thead">
            <vs-th class="th-justify-center">
              Não
            </vs-th>

            <vs-th>
              Opção
            </vs-th>

            <vs-th class="th-justify-center">
              <div class="custom-td-check">
                <vs-checkbox
                  v-model="allSelected"
                  :vs-value="selectedQuestion.indId"
                  @change="selectAll"
                >
                  <span class="custom-td-check--text">
                    Incluir
                  </span>
                </vs-checkbox>
              </div>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              v-for="(answer, answerIdx) in data"
              :key="answerIdx"
            >
              <div class="d-flex justify-content-center align-items-center p-3">
                <vs-switch
                  v-model="selectedAnswers"
                  :vs-value="answer.item + ':!'"
                  vs-icon="remove"
                  @click="updateControls(answer.item, 'negative')"
                />
              </div>

              <vs-td>
                {{ answer.item.split(':')[1] }}
              </vs-td>

              <vs-td>
                <vs-checkbox
                  v-model="selectedAnswers"
                  :vs-value="answer.item"
                  @change="updateControls(answer.item, 'positive')"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <span v-else>
          Nenhuma opção disponível.
        </span>
      </div>

      <!-- Add Custom Filter -->
      <div v-if="showCustomFilter(selectedQuestion)">
        <!-- Like Text Search -->
        <div v-if="customFilterOption === 'like'">
          <h3 class="mb-3 mt-2">
            Filtrar por equivalência de texto livre:
          </h3>

          <p class="mb-2">
            Texto:
          </p>

          <vs-input
            v-model="searchText"
            class="w-50"
          />
        </div>

        <!-- Date Range Filter -->
        <div v-if="customFilterOption === 'date'">
          <h3 class="mb-3 mt-2">
            Filtrar por intervalo de datas:
          </h3>

          <vs-row>
            <vs-col vs-lg="3">
              <p class="my-0">
                Data inicial:
              </p>

              <vs-input
                v-model="dateRange[0]"
                v-mask="'##/##/####'"
                placeholder="dd/mm/yyyy"
              />
            </vs-col>

            <vs-col vs-lg="3">
              <p class="my-0">
                Data final:
              </p>

              <vs-input
                v-model="dateRange[1]"
                v-mask="'##/##/####'"
                placeholder="dd/mm/yyyy"
              />
            </vs-col>
          </vs-row>

          <vs-alert
            v-show="!validDateRange"
            color="danger"
            class="mt-2 w-50"
          >
            A data inicial é maior ou igual a data final.
          </vs-alert>

          <vs-alert
            v-show="!validFirstDate"
            color="danger"
            class="mt-2 w-50"
          >
            A primeira data é inválida.
          </vs-alert>

          <vs-alert
            v-show="!validLastDate"
            color="danger"
            class="mt-2 w-50"
          >
            A segunda data é inválida.
          </vs-alert>
        </div>

        <!-- Time Range Filter -->
        <div v-if="customFilterOption === 'time'">
          <h3 class="mb-3 mt-2">
            Filtrar por intervalo de horas:
          </h3>

          <vs-row>
            <vs-col vs-lg="3">
              <p class="my-0">
                Hora inicial:
              </p>

              <vs-input
                v-model="timeRange[0]"
                v-mask="'##:##'"
                placeholder="00:00"
              />
            </vs-col>

            <vs-col vs-lg="3">
              <p class="my-0">
                Hora final:
              </p>

              <vs-input
                v-model="timeRange[1]"
                v-mask="'##:##'"
                placeholder="00:00"
              />
            </vs-col>
          </vs-row>

          <vs-alert
            v-show="!validTimeRange"
            color="danger"
            class="mt-2 w-50"
          >
            A hora final é menor que a hora inicial.
          </vs-alert>
        </div>

        <!-- Value Range Filter -->
        <div v-if="customFilterOption === 'int'">
          <h3 class="mb-3 mt-2">
            Selecionar alcance de valores
          </h3>

          <vs-row>
            <vs-col vs-lg="3">
              <p class="my-0">
                Valor mínimo:
              </p>

              <vs-input
                v-model="valueRange[0]"
                type="number"
              />
            </vs-col>

            <vs-col vs-lg="3">
              <p class="my-0">
                Valor máximo:
              </p>

              <vs-input
                v-model="valueRange[1]"
                type="number"
              />
            </vs-col>
          </vs-row>
        </div>

        <vs-button
          class="mt-4"
          :disabled="!validCustomFilter || !validDateRange || !validTimeRange"
          @click="confirmAnswers"
        >
          Confirmar Pesquisa
        </vs-button>
      </div>
    </vs-popup>

    <SelectFilter
      :select-filter-popup="selectFilterPopup"
      :saved-filters="savedFilters"
      @close-popup="closeSelectFltPopup"
      @delete-filter="deleteFilter"
      @select-saved-filter="selectSavedFilter"
    />
  </div>
</template>

<script>
/* Components */
import FilterHeader from './FilterHeader.vue';
import ResultsCard from './ResultsCard.vue';
import SelectFilter from './SelectFilter.vue';

/* Helpers */
import { mask } from 'vue-the-mask';

/* Validations */
import validateCustomFilter from './helpers/validate-custom-filter';
import validateFirstDate from './helpers/validate-first-date';
import validateLastDate from './helpers/validate-last-date';
import validateDateRange from './helpers/validate-date-range';
import validateTimeRange from './helpers/validate-time-range';

/* Services */
import Fan from '@/services/fan';
import Filter from '@/services/filter';
import Survey from '@/services/survey';

const fanService = new Fan();
const filterService = new Filter();
const surveyService = new Survey();

/* Style */
import './assets/styles.scss';

export default {
  name: 'Filters',
  components: {
    FilterHeader,
    ResultsCard,
    SelectFilter,
  },
  directives: { mask },
  props: {
    returnLink: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    questionGroups: [],
    questions: [],
    selectedQuestion: {},
    /* Answers */
    /*
      The "answers" and "selectedAnswers" data props store an Array
      of strings, each string is made out of three parts: the first part,
      before the colon, is the question id that answer belongs to,
      while the second part, after the colon, is the actual answer value.
      Both parts are essential since multiple questions can share
      the same answer - e.g. YES or NO. The third part is the config,
      it can be either "!" for negative, or empty for positive.

      Although it is always best to use an object in these cases, this
      was done as a workaround for the Vuesax checkbox component.
      In short, the checkbox value is based on whether, or not, the
      value exists in an array or not. Since two JavaScript objects
      occupy different parts of computer memory and cannot be equal
      each other, the v-model and vs-value properties can only allow
      strings.
    */
    answers: [],
    selectedAnswers: [],
    operators: [
      { value: '|', text: 'Ou' },
      { value: '&', text: 'E' }
    ],
    allSelected: [],
    selectedOperator: '|',
    /* Counter */ 
    answerRenderKey: 0,
    registerCount: 0,
    /* States */
    creatingFilter: true,
    filterSaved: true,
    loadingResults: false,
    previousResults: false,
    /* Popups */
    selectAnswerPopup: false,
    selectFilterPopup: false,
    /* Filter Props */
    filterId: 0, // Saved or not saved
    savedFilters: [],
    filterName: '',
    filterUid: '-',
    filterAnswers: [],
    /* Custom Filter */
    dateRange: [],
    timeRange: [],
    valueRange: [],
    searchText: '',
    /* Other */
    csvUrl: '', // Export Contacts CSV
    timeoutCounter: 0,
  }),
  computed: {
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    answersNotEmpty() {
      return this.answers.length > 0;
    },
    filterNotEmpty() {
      return this.filterAnswers.length > 0;
    },
    validCustomFilter() {
      return this.validateCustomFilter();
    },
    validFirstDate() {
      return this.validateFirstDate();
    },
    validLastDate() {
      return this.validateLastDate();
    },
    validDateRange() {
      return this.validateDateRange();
    },
    validTimeRange() {
      return this.validateTimeRange();
    },
    idToken() {
      return this.$store.state.accessToken.replace('tk=', '');
    },
    /*
      In order for Vuesax's table search to work properly, it requires an array of objects, each containing
      an "item" key storing our table item, a.k.a answer, hence the name 'vsAnswers'.
    */
    vsAnswers() {
      return this.answers.map((item) => ({ item }));
    },
    customFilterOption() {
      if (!this.showCustomFilter(this.selectedQuestion)) return '';

      const split = this.selectedQuestion.filterOptions.split(';');

      return split[0];
    },
    showFilterAnswers() {
      if (!this.showCustomFilter(this.selectedQuestion)) return true;

      const split = this.selectedQuestion.filterOptions.split(';');

      if (!split[1]) return true;

      return split[1] === 's';
    },
    multipleSelected() {
      // Return "real" selected answers, since every negative answer has a positive double
      const positive = this.selectedAnswers.filter((answer) => answer.split(':')[2] !== '!');

      return positive.length >= 2;
    },
    multipleNegative() {
      const negative = this.selectedAnswers.filter((answer) => answer.split(':')[2] === '!');

      return negative.length >= 2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  created() {
    this.getQuestionGroups();

    if (this.$store.state.filterOptions.length > 0) {
      this.previousResults = true;

      this.filterAnswers = this.$store.state.filterOptions;

      this.questions = this.$store.state.filterQuestions;

      this.questionGroups = this.$store.state.categoriaFiltro;

      if (this.$store.state.filterName) {
        this.filterName = this.$store.state.filterName;
      }
    }
  },
  mounted() {
    this.filterUid = this.$store.state.filterUid;

    if (this.filterUid) this.getIdCount();

    // Set filterSaved
    this.$store.dispatch('set_isLoadingActive', true);

    filterService
      .getFilters(this.ownerId)
      .then(
        (resp) => {
          this.$store.dispatch('set_isLoadingActive', false);

          const filter = resp.find((f) => f.uuid === this.filterUid);

          if (filter) {
            this.filterSaved = true;
            this.creatingFilter = false;
          } else {
            this.creatingFilter = true;
            this.filterSaved = false;
            this.filterName = '';
          }
        },
        (err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        });
  },
  methods: {
    /* API */
    getCSVUrl(filterUid) {
      // Generate temporary filterUid for CSV download
      this.csvUrl = fanService.getCSVUrl(
        this.ownerId,
        filterUid,
        this.idToken
      );
    },
    getIdCount() {
      if (this.filterUid != '') {
        this.$store.dispatch('set_isLoadingActive', true);

        fanService
          .getIdsRegisterCount(
            this.ownerId,
            this.filterUid
          )
          .then(
            (resp) => {
              this.$store.dispatch('set_isLoadingActive', false);

              if (resp.nRegisters === -1) {
                this.handleTimeout();
              } else {
                this.registerCount = parseInt(resp);
                this.previousResults = true;
              }
            },
            (err) => {
              this.$store.dispatch('set_isLoadingActive', false);

              alert(err);
            }
          );
      } else {
        alert('Nenhuma opção de filtro selecionada.');
      }
    },
    getQuestionGroups() {
      // Get and set question groups
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService
        .getBoxesAsFilters(this.ownerId, this.token)
        .then((res) => {
          this.questionGroups = res;

          this.$store.commit('SET_filterAllQuestions', res);
          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        });
    },
    /* Answers */
    getAnswers(questionId) {
      this.answers = [];

      this.$store.dispatch('set_isLoadingActive', true);

      surveyService
        .getAnswer(
          this.ownerId,
          questionId,
          this.token
        )
        .then(
          ({ answers }) => {
            this.answers = answers.map((item) => `${questionId}:${item.answer}`);

            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },
    addDateRange() {
      this.addAnswer(
        this.selectedQuestion.indId,
        this.dateRange[0],
        this.selectedOperator
      );

      this.addAnswer(
        this.selectedQuestion.indId,
        this.dateRange[1],
        this.selectedOperator
      );
    },
    addTextSearch() {
      this.addAnswer(
        this.selectedQuestion.indId,
        this.searchText,
        this.selectedOperator
      );
    },
    addTimeRange() {
      this.addAnswer(
        this.selectedQuestion.indId,
        this.timeRange[0],
        this.selectedOperator
      );

      this.addAnswer(
        this.selectedQuestion.indId,
        this.timeRange[1],
        this.selectedOperator
      );
    },
    addValueRange() {
      this.addAnswer(
        this.selectedQuestion.indId,
        this.valueRange[0],
        this.selectedOperator
      );

      this.addAnswer(
        this.selectedQuestion.indId,
        this.valueRange[1],
        this.selectedOperator
      );
    },
    addAnswer(questionId, answer, config) {
      // Find target question
      let question = this.filterAnswers.find((q) => q.id === questionId);

      if (question) {
        // Check added
        const answerExists = question.options.find(({ option }) => option === answer);

        if (answerExists) {
          // Update config
          answerExists.config = config;
        } else {
          question.options.push({
            option: answer,
            config,
          });
        }
      } else {
        // If question doesn't exist in filters, add it with the selected answer
        this.filterAnswers.push({
          id: questionId,
          options: [
            {
              option: answer,
              config
            }
          ],
          questionName: this.selectedQuestion.idxText,
          filterOptions: this.selectedQuestion.filterOptions || null
        });
      }

      this.answerRenderKey++;
    },
    removeAnswer(questionId, answer) {
      // Find target question
      const question = this.filterAnswers.find((a) => a.id === questionId);

      // Update question answers
      question.options = question.options.filter(({ option }) => option !== answer);

      // If question answers is empty, remove question from selected answers
      if (question.options.length === 0) {
        this.filterAnswers = this.filterAnswers.filter((a) => a.id !== questionId);
      }

      // If filter answers is empty, clear filter entirely
      if (!this.filterNotEmpty) {
        this.clearFilter();
      }

      // Remove answer from selected answers
      const positiveAnswer = `${questionId}:${answer}`;
      const negativeAnswer = `${questionId}:${answer}:!`;

      this.selectedAnswers = this.selectedAnswers
        .filter((a) => a !== positiveAnswer)
        .filter((a) => a !== negativeAnswer);

      this.answerRenderKey++;
    },
    selectAll() {
      const { indId: questionId } = this.selectedQuestion;

      const includesAll = this.answers
        .every((answer) => this.selectedAnswers.includes(answer));

      if (!includesAll) {
        // Add remaining unselected answers
        this.answers.forEach((answer) => {
          const includes = this.selectedAnswers.includes(answer);

          if (!includes) {
            this.selectedAnswers.push(answer);
          }
        });
      } else if (includesAll) {
        // Remove all selected answers
        const updated = this.selectedAnswers
          .filter((a) => Number(a.split(':')[0]) !== questionId);

        this.selectedAnswers = updated;
      }
    },
    setFilterAnswers() {
      // Change selected operator
      if (this.multipleNegative) {
        this.selectedOperator = '&';
      }

      if (this.selectedAnswers.length === 0) {
        this.filterAnswers = [];
        this.answerRenderKey++;
      } else {
        let realSelected = [...this.selectedAnswers];

        this.selectedAnswers.forEach((answer) => {
          const questionId = answer.split(':')[0];
          const value = answer.split(':')[1];
          const isNeg = answer.split(':')[2] === '!';

          // Remove answer positive/negative double 
          if (isNeg) {
            realSelected = realSelected
              .filter((a) => a !== `${questionId}:${value}`);
          }
        });

        // Add Each
        realSelected.forEach((answer) => {
          const questionId = Number(answer.split(':')[0]);
          const value = answer.split(':')[1];
          const negative = answer.split(':')[2] === '!';

          let config;

          if (negative && !this.multipleSelected) {
            config = '!';
          } else if (negative && this.multipleSelected) {
            config = this.selectedOperator + '!';
          } else if (!negative) {
            config = this.selectedOperator;
          }

          this.addAnswer(questionId, value, config);
        });

        // Remove Each
        const regularAnswers = this.filterAnswers.filter((question) => !question.filterOptions);

        regularAnswers.forEach((question) => {
          const { id, options } = question;

          options.forEach(({ option, config }) => {
            const stringConfig = config.includes('!') ? ':!' : '';

            const searchString = `${id}:${option}${stringConfig}`;

            // Find option
            const includes = realSelected.includes(searchString);

            if (!includes) {
              this.removeAnswer(id, option);
            }
          });
        });
      }
    },
    confirmAnswers() {
      const hasTimeRange = this.timeRange.length > 0;
      const hasDateRange = this.dateRange.length > 0;
      const hasValueRange = this.valueRange.length > 0;
      const hasTextSearch = this.searchText.length > 0;

      if (
        (hasTimeRange
        || hasDateRange
        || hasValueRange
        || hasTextSearch)
        && this.selectedAnswers.length === 0
      ) {
        if (hasTimeRange) this.addTimeRange();
        if (hasDateRange) this.addDateRange();
        if (hasValueRange) this.addValueRange();
        if (hasTextSearch) this.addTextSearch();
      } else {
        this.setFilterAnswers();

        if (hasTimeRange) this.addTimeRange();
        if (hasDateRange) this.addDateRange();
        if (hasValueRange) this.addValueRange();
        if (hasTextSearch) this.addTextSearch();
      }

      // Reset filter state
      this.filterSaved = false;
      this.previousResults = false;

      // Reset popup inputs
      this.selectedOperator = '|';
      this.selectAnswerPopup = false;
    },
    /* Filter */
    applyFilter() {
      this.previousResults = true;

      if (this.filterNotEmpty) {
        if (this.creatingFilter) this.filterUid = '-';

        this.loadingResults = true;

        this.$store.dispatch('set_isLoadingActive', true);

        filterService
          .prepareFilter(
            this.ownerId,
            this.filterUid,
            this.filterAnswers,
          )
          .then(
            (resp) => {
              this.$store.dispatch('set_isLoadingActive', false);

              this.loadingResults = false;

              if (resp.nRegisters === -1) {
                this.handleTimeout();
              } else {
                this.filterUid = resp.filterUid;
                this.registerCount = resp.nRegisters;

                this.getCSVUrl(resp.filterUid);

                this.$store.commit('SET_filterUid', resp.filterUid);
                this.$store.commit('SET_selectedContacts', resp.nRegisters);
              }
            },
            (err) => {
              this.$store.dispatch('set_isLoadingActive', false);

              this.loadingResults = false;

              alert(err);
            }
          );
      }
    },
    saveFilter() {
      if (this.filterNotEmpty) {
        this.$store.dispatch('set_isLoadingActive', true);

        filterService
          .saveFilter(
            this.ownerId,
            this.filterName,
            1,
            this.filterId,
            this.filterUid,
          )
          .then(
            (resp) => {
              this.filterId = resp;

              this.$store.dispatch('set_isLoadingActive', false);

              this.filterSaved = true;

              this.$vs.notify({
                title: 'Filtro gravado com sucesso',
                text: this.filterName,
              });
            },
            (err) => {
              this.$store.dispatch('set_isLoadingActive', false);
              alert(err);
            }
          );
      } else {
        alert('Nenhuma opção de filtro selecionada.');
        this.filterName = '';
      }
    },
    clearFilter() {
      // Clear selected answers
      this.filterAnswers = [];
      this.selectedAnswers = [];

      // Reset filter state
      this.filterId = 0;
      this.filterName = '';
      this.previousResults = false;
      this.creatingFilter = true;
      this.filterSaved = true;

      // Force update answer chips color
      this.answerRenderKey++;
    },
    deleteFilter(filterUuid) {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService
        .deleteFilter(filterUuid)
        .then(
          () => {
            this.$store.dispatch('set_isLoadingActive', false);

            this.getFilters();
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        )
    },
    getFilters() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService
        .getFilters(this.ownerId)
        .then(
          (resp) => {
            this.savedFilters = resp;
            this.$store.dispatch('set_isLoadingActive', false);

            this.openSelectFltPopup();

            this.previousResults = false;
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },
    getSavedFilter() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService
        .getFiltersOptions(this.ownerId, this.filterId)
        .then(
          (resp) => {
            this.filterAnswers = resp;

            this.getIdCount();

            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },
    selectSavedFilter(filter) {
      this.clearFilter();

      this.$vs.notify({
        title: 'Selecionado',
        text: `Filtro:  ${filter.name}`,
      });

      // Set filter properties
      this.filterId = filter.filterId;
      this.filterName = filter.name;
      this.filterUid = filter.uuid;
      this.selectFilterPopup = false;
      this.creatingFilter = false;

      this.getCSVUrl(filter.uuid);

      this.getSavedFilter();
    },
    /* Helpers */
    getOptionsOperator(questionId) {
      // Find question
      const question = this.filterAnswers.find((a) => a.id === questionId);

      if (question.options[0].config) {
        // Find logic operator
        const operator = question.options
          .find(({ config }) => config.includes('&') || config.includes('|'));

        // Return "&" or "|" operator
        if (operator) {
          return operator.config.replace('!', '');
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    handleTimeout() {
      this.timeoutCounter++;

      if (this.timeoutCounter === 1) {
        this.tryAgainDialog();

        this.previousResults = false;
      } else if (this.timeoutCounter === 2) {
        this.timeoutDialog();

        this.timeoutCounter = 0;

        this.clearFilter();
      }
    },
    showCustomFilter(selectedQuestion) {
      if (!selectedQuestion.filterOptions) return false;

      return !(!selectedQuestion.filterOptions.includes(';'));
    },
    showSelectAnswerPopup(selectedQuestion) {
      // Block popup if indicator is under maintenance
      if (selectedQuestion.indId !== 0) {
        let question = selectedQuestion;

        if (!selectedQuestion.indId) {
          question = {
            indId: selectedQuestion.id,
            idxText: selectedQuestion.questionName,
            filterOptions: selectedQuestion.filterOptions || null
          }
        }

        this.selectedQuestion = question;

        // Clear custom filters
        this.dateRange = [];
        this.timeRange = [];
        this.valueRange = [];
        this.searchText = '';

        // Get answers only if the selected indicator has options
        if (this.customFilterOption.length === 0) {
          this.getAnswers(question.indId);
        }

        this.selectAnswerPopup = true;
      }
    },
    updateControls(answer, config) {
      const questionId = answer.split(':')[0];
      const value = answer.split(':')[1];

      const positiveAnswer = `${questionId}:${value}`;
      const negativeAnswer = `${questionId}:${value}:!`;

      const includesPos = this.selectedAnswers.includes(positiveAnswer);
      const includesNeg = this.selectedAnswers.includes(negativeAnswer);

      // Add to allSelected list if all selected
      const includesAll = this.answers
        .every((a) => this.selectedAnswers.includes(a));

      if (includesAll && !this.allSelected.includes(Number(questionId))) {
        this.allSelected.push(Number(questionId));
      }

      // Remove from allSelected list if all unselected
      if (!includesAll && this.allSelected.includes(Number(questionId))) {
        const updated = this.allSelected.filter((questId) => questId !== Number(questionId));

        this.allSelected = updated;
      }

      // Include positive answer if negative switch is on
      if (config === 'negative') {
        if (!includesPos) {
          this.selectedAnswers = [
            ...this.selectedAnswers,
            positiveAnswer,
          ];
        }
      } else if (config === 'positive') {
        if (includesNeg) {
          this.selectedAnswers = this.selectedAnswers
            .filter((a) => a !== negativeAnswer);
        }
      }
    },
    /* Validations */
    validateCustomFilter() {
      return validateCustomFilter(
        this.customFilterOption,
        this.searchText,
        this.dateRange,
        this.timeRange,
        this.valueRange,
      );
    },
    validateFirstDate() {
      return validateFirstDate(this.dateRange);
    },
    validateLastDate() {
      return validateLastDate(this.dateRange);
    },
    validateDateRange() {
      return validateDateRange(this.dateRange);
    },
    validateTimeRange() {
      return validateTimeRange(this.timeRange);
    },
    /* Popups */
    closeSelectFltPopup() {
      this.selectFilterPopup = false;
    },
    openSelectFltPopup() {
      this.selectFilterPopup = true;
    },
    /* Alerts */
    timeoutDialog() {
      this.$vs.dialog({
        color: 'danger',
        title: 'Erro',
        text: 'Houve um problema com sua consulta, por favor entre em contato com suporte.',
        acceptText: 'OK',
      });
    },
    tryAgainDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Tente novamente',
        text: 'Sua consulta está demorando mais do que o esperado, deseja tentar novamente?',
        acceptText: 'Sim',
        cancelText: 'Não',
        accept: this.applyFilter,
        cancel: this.clearFilter,
      });
    },
  },
};
</script>
