<template>
  <div>
    <vs-popup
      id="filters-select-filter"
      :active.sync="selectFilterPopup"
      title="Selecionar Filtro"
      @cancel="closePopup"
      @close="closePopup"
    >
      <vs-table
        class="text-nowrap"
        :data="savedFilters"
        :max-items="10"
        pagination
        :search="search"
        stripe
        max-height="525px"
      >
        <template slot="thead">
          <vs-th sort-key="id">
            Id
          </vs-th>

          <vs-th sort-key="contactname">
            Nome
          </vs-th>

          <vs-th class="d-flex justify-content-center">
            Deletar
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            v-for="filter in data"
            :key="filter.filterId"
          >
            <vs-td :data="filter.filterId">
              <h5
                class="font-16 mb-0 cursor-pointer"
                @click="selectSavedFilter(filter)"
              >
                {{ filter.filterId }}
              </h5>
            </vs-td>

            <vs-td :data="filter.name">
              <h5
                class="font-16 mb-0 cursor-pointer"
                @click="selectSavedFilter(filter)"
              >
                {{ filter.name }}
              </h5>
            </vs-td>

            <vs-td class="d-flex justify-content-center">
              <vs-button
                radius
                color="transparent"
                class="p-0 text-dark"
                title="Deletar Filtro"
                @click="openDeleteDialog(filter)"
              >
                <i
                  aria-hidden="true"
                  class="fa-trash fas m-2"
                  :style="{ color: '#f62d51' }"
                />
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>

    <vs-prompt
      :active.sync="deleteDialog"
      title="Deletar filtro"
      type="confirm"
      cancel-text="Cancelar"
      accept-text="Deletar"
      @cancel="closeDeleteDialog"
      @accept="() => deleteFilter(selectedFilter.uuid)"
    >
      <b class="h6">
        Tem certeza que deseja deletar o filtro: "{{ selectedFilter.name }}"?
      </b>
    </vs-prompt>
  </div>
</template>

<script>
export default {
  name: 'SelectFilter',
  props: {
    selectFilterPopup: {
      type: Boolean,
      required: true,
    },
    savedFilters: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: '',
    selectedFilter: {},
    deleteDialog: false,
  }),
  methods: {
    selectSavedFilter(filter) {
      this.$emit('select-saved-filter', filter);

      this.closePopup();
    },
    deleteFilter(filterUuid) {
      this.closeDeleteDialog();

      this.$emit('delete-filter', filterUuid);
    },
    /* Popups */
    closePopup() {
      this.$emit('close-popup');
    },
    closeDeleteDialog() {
      this.selectedFilter = {};

      this.deleteDialog = false;
    },
    openDeleteDialog(filter) {
      this.selectedFilter = filter;

      this.deleteDialog = true;
    },
  },
};
</script>

<style>
#filters-select-filter .vs-popup--content {
  overflow: hidden !important;
}

#filters-select-filter .vs-table--content {
  overflow-x: hidden !important;
}
</style>
