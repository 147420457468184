<template>
  <vs-col
    v-if="previousResults"
    type="flex"
    vs-justify="center"
    vs-align="center"
    vs-lg="4"
    vs-xs="12"
  >
    <h4 class="align-self-center card-title title">
      Resultado
    </h4>

    <vs-card
      actionable
      class="cardx"
    >
      <div slot="header">
        <vs-row class="d-flex justify-content-between">
          <vs-col
            vs-lg="9"
            vs-xs="9"
            class="align-self-center"
          >
            <h5 class="text-uppercase title">
              Sua pesquisa retornou
            </h5>
          </vs-col>
        </vs-row>
      </div>

      <vs-row class="mb-2">
        <!-- Number of Ids -->
        <vs-col
          vs-lg="12"
          vs-xs="12"
          class="justify-content-center mb-3 mt-3"
        >
          <p
            class="font-weight-bold mb-0 text-center title"
            :style="{ color: colorSecondary }"
          >
            <span
              v-if="loadingResults"
              style="font-size: 1.5rem; line-height: 5.2rem"
            >
              Carregando...
            </span>

            <span
              v-else
              style="font-size: 3rem; line-height: 5.2rem"
            >
              {{ registerCount | formatLocale }}
            </span>
          </p>

          <p class="mb-0 text-center">
            <span
              class="font-weight-bold text-dark text-uppercase"
              style="font-size: 1rem"
            >
              {{ registerCount === 1 ? "registro" : "registros" }}
            </span>

            <i class="fa-lg mdi mdi-account ml-1 mr-1 text-dark" />
          </p>
        </vs-col>

        <!-- Actions -->
        <div class="filter-result-actions">
          <!-- Go To Registers -->
          <vs-button
            type="filled"
            color="dark"
            size="small"
            icon="people"
            @click="goToContacts"
          >
            Lista de registros
          </vs-button>

          <!-- Create Campaign -->
          <vs-button
            color="dark"
            size="small"
            icon="record_voice_over"
            @click="goToCampaign"
          >
            Nova Campanha
          </vs-button>

          <!-- Export CSV -->
          <a
            :href="!checkItemAccess('LGPD') ? '' : csvUrl"
            :style="!checkItemAccess('LGPD') ? { pointerEvents: 'none', cursor: 'not-allowed' } : undefined"
            rel="noopener noreferrer"
            download
          >
            <vs-tooltip text="Exportar CSV">
              <vs-button
                :disabled="!checkItemAccess('LGPD')"
                color="dark"
                size="small"
                icon="file_download"
              />
            </vs-tooltip>
          </a>
        </div>

        <!-- Save Filter -->
        <vs-col
          v-if="!filterSaved"
          vs-lg="12"
          vs-xs="12"
          class="mb-2 mt-3 px-3"
        >
          <div class="mt-1">
            <vs-input
              v-model="filterNameModel"
              icon-after="true"
              icon="mode_edit"
              placeholder="Nome Filtro"
              class="mb-1 w-100"
            />

            <vs-button
              class="mt-2 w-100"
              @click="saveFilter"
            >
              <i class="mdi mdi-content-save mr-1" />

              Gravar Filtro
            </vs-button>
          </div>
        </vs-col>

        <vs-col
          v-else-if="checkItemAccess('ActiveCampaign')"
          vs-lg="12"
          vs-xs="12"
          class="mt-2 px-3"
        >
          <vs-col class="d-flex justify-content-center w-100">
            <b style="color: #615D5D;">
              ou
            </b>
          </vs-col>

          <div class="logo-btn-wrapper">
            <button
              class="logo-btn"
              @click="integrate"
            >
              <span>Exportar para</span>

              <div class="img-wrapper">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/0/01/ActiveCampaign_logo.svg"
                  alt="ActiveCampaign"
                >
              </div>
            </button>
          </div>
        </vs-col>
      </vs-row>
    </vs-card>
  </vs-col>
</template>

<script>
/* Services */
import Fan from '@/services/fan';

const fanService = new Fan();

export default {
  name: 'ResultsCard',
  props: {
    csvUrl: {
      type: String,
      required: true,
    },
    filterAnswers: {
      type: Array,
      required: true,
    },
    filterId: {
      type: Number,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
    filterSaved: {
      type: Boolean,
      required: true,
    },
    filterUid: {
      type: String,
      required: true,
    },
    loadingResults: {
      type: Boolean,
      required: true,
    },
    previousResults: {
      type: Boolean,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    questionGroups: {
      type: Array,
      required: true,
    },
    registerCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    accessValues() {
      return this.$store.state.loginData.accessValues;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    filterNameModel: {
      get() { return this.filterName; },
      set(value) { this.$emit('set-filter-name', value); }
    },
  },
  methods: {
    /* API */
    integrate() {
      this.$store.dispatch('set_isLoadingActive', true);

      const payload = {
        ownerId: this.ownerId,
        filterId: this.filterId,
      };

      fanService
        .integrate(payload)
        .then(
          () => {
            this.$store.dispatch('set_isLoadingActive', false);

            this.successAlert(
              'Sucesso',
              `"${this.filterName}" foi exportado com sucesso.`
            );
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);

            this.errAlert('Erro', err);
          }
        );
    },
    saveFilter() {
      this.$emit('save-filter');
    },
    /* Helpers */
    checkItemAccess(access) {
      if (!access) return true;

      return this.accessValues.includes(access);
    },
    /* Alerts */
    errAlert(title, text) {
      this.$vs.notify({
        color: 'danger',
        title,
        text,
      });
    },
    successAlert(title, text) {
      this.$vs.notify({
        color: 'success',
        title,
        text,
      });
    },
    /* Redirects */
    goToCampaign() {
      if (!this.filterSaved) {
        alert('É preciso salvar o filtro antes de prosseguir.');
      } else {
        this.$router.push('/Campanhas/criar');
      }
    },
    goToContacts() {
      this.$store.commit('SET_filterOptions', this.filterAnswers);
      this.$store.commit('SET_filterQuestions', this.questions);
      this.$store.commit('SET_categoriaFiltro', this.questionGroups);

      if (this.filterName) {
        this.$store.commit('SET_filterName', this.filterName);
      }

      if (this.filterUid !== '') {
        this.$store.dispatch('setFilterUid', this.filterUid);

        this.$router.push({
          name: 'Lista de Registros',
          params: {
            fromFilter: true,
            filterSaved: this.filterId !== 0
          }
        });
      } else {
        alert('Nenhuma opção de filtro selecionada.');
      }
    },
  },
};
</script>
