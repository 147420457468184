import moment from 'moment';

const validateTimeRange = (timeRange) => {
  const validTimeLength = 5;

  if (timeRange[0] && timeRange[1]) {
    if (
      timeRange[0].length === validTimeLength
      && timeRange[1].length === validTimeLength
    ) {
      return moment(timeRange[0], 'hh:mm')
        .isBefore(moment(timeRange[1], 'hh:mm'));
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export default validateTimeRange;
